//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { imgChange, numChange } from "@/commons";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from "axios";
export default {
  components: {
    UserList: () => import("./user-list"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    InvitationUser: () => import("./invitation-user"),
    AddTeam: () => import("./add-team"),
    InitdataModal: () => import("./initdata-modal"),
  },
  props: {
    //选择的团队
    selRow: {
      type: Object,
      default: null,
    },
    //团队详情
    infoData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      transferMember: [], //主管理员
      selApplyMem: [], //审核人
      loading: false,
      setOne: true,
      setTwo: true,
      setThree: false,
      setFour: false,
      setFive: false,
      passwordVal: null, //口令
      addressArray: [],
      IsOpenChick: true, //是否开启打卡功能
      IsNeedCheck: true, //成员申请是否需要审核
      CanCancelAutoStart: true, //成员是否可以隐藏开机启动
      IsMonitoringIdleTime: true, //是否监测空闲时间
      ClientRunType: 1, //0 不执行 1开机自动运行，2开机自动运行并隐藏
      IsAutoCreateBulletin: true,
      VisiblesByTeam: [],
      IsMonitoringNetwork: true,
      IsMonitoringPerformance: true,
      IsMonitoringFile: true,
      IsMonitoringPrint: true,
      IsAutoStorageVideo: false,
      IsRealTimeScreenshot: true,
      IsTimeScreenshot: true,
      IsEnableLocalStore: false,
      IsEnableTeamPackage: true,
      isDownloading: false,
      progressNum: 0,
      teamPackageDialogVisiable: false,
      teamPackageDialogTime: null,
    };
  },
  watch: {
    infoData(val, oval) {
      if (val != oval) {
        this.setOne = this.infoData.Teamdata.IsTeamCode == 1 ? true : false;
        this.setTwo = this.infoData.Teamdata.IsAgree == 1 ? true : false;
        this.setThree =
          this.infoData.Teamdata.IsInvitationCode == 1 ? true : false;
        this.passwordVal = this.infoData.Teamdata.InvitationCode;
        this.setFour = this.infoData.Teamdata.IsStartCamera;
        this.setFive = this.infoData.Teamdata.IsWarn;
        this.addressArray =
          this.infoData.Teamdata.WarnEmails &&
          this.infoData.Teamdata.WarnEmails.length
            ? this.infoData.Teamdata.WarnEmails.map((m) => {
                return {
                  inputVal: m,
                };
              })
            : [];
        this.IsOpenChick = this.infoData.Teamdata.IsOpenChick;
        this.IsNeedCheck = this.infoData.Teamdata.IsNeedCheck;
        this.CanCancelAutoStart = this.infoData.Teamdata.CanCancelAutoStart;
        this.IsMonitoringIdleTime = this.infoData.Teamdata.IsMonitoringIdleTime;
        this.ClientRunType = this.infoData.Teamdata.ClientRunType;
        this.IsAutoCreateBulletin = this.infoData.Teamdata.IsAutoCreateBulletin;
        this.selApplyMem =
          this.infoData.Teamdata.AuditorByTeam &&
          this.infoData.Teamdata.AuditorByTeam.length
            ? this.infoData.Teamdata.AuditorByTeam
            : [];
        this.VisiblesByTeam =
          this.infoData.Teamdata.VisiblesByTeam &&
          this.infoData.Teamdata.VisiblesByTeam.length
            ? this.infoData.Teamdata.VisiblesByTeam
            : [];
        this.IsMonitoringNetwork = this.infoData.Teamdata.IsMonitoringNetwork;
        this.IsMonitoringPerformance =
          this.infoData.Teamdata.IsMonitoringPerformance;
        this.IsMonitoringFile = this.infoData.Teamdata.IsMonitoringFile;
        this.IsMonitoringPrint = this.infoData.Teamdata.IsMonitoringPrint;
        this.IsAutoStorageVideo = this.infoData.Teamdata.IsAutoStorageVideo;
        this.IsRealTimeScreenshot = this.infoData.Teamdata.IsRealTimeScreenshot;
        this.IsTimeScreenshot = this.infoData.Teamdata.IsTimeScreenshot;
        this.IsEnableLocalStore = this.infoData.Teamdata.IsEnableLocalStore;
        this.IsEnableTeamPackage = this.infoData.Teamdata.IsEnableTeamPackage;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    imgChange,
    numChange,
    checkFunction(func) {
      if (!this.infoData.Teamdata.Functions) {
        return false;
      }
      return this.infoData.Teamdata.Functions.indexOf(func) != -1;
    },
    progressBar(progressEvent) {
      // 获取百分比（此处保留了一位小数）
      let percentage = Number(
        ((progressEvent.loaded / progressEvent.total) * 100).toFixed(1)
      );
      this.$nextTick(() => {
        this.progressNum = percentage;
      });

      // 让百分比显示在界面上
    },
    downloadTeamPackage() {
      this.isDownloading = true;
      const getFile = (url, callback) => {
        return new Promise((resolve, reject) => {
          axios({
            method: "get", //使用get请求
            url, //放入的文件地址
            responseType: "arraybuffer", //请求的数据buffer对象
            onDownloadProgress: function (progressEvent) {
              //这一步是下载文件进度条，可以和progress进度条一块使用，不过多个文件下载会有很丑的动画，建议同步下载显示进度条
              // 对原生进度事件的处理
              if (callback) {
                callback(progressEvent);
              }
            },
          })
            .then((data) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error.toString());
            });
        });
      };
      this.$http
        .post("/Teams/GetTeamPackageInfo.ashx", { teamId: this.selRow.Id })
        .then((resp) => {
          if (resp.res == 0) {
            var teamId = resp.data.Id;
            const zip = new JSZip();
            zip.file("setup.ini", `[TeamInfo]\r\nId=${teamId}`);
            const promises = [];
            const promise = getFile(
              "/EZTeam-Installer.exe",
              this.progressBar
            ).then((data) => {
              zip.file("EZTeam-Installer.exe", data);
            });
            promises.push(promise);
            Promise.all(promises)
              .then(() => {
                zip
                  .generateAsync({ type: "blob" })
                  .then(function (content) {
                    saveAs(content, "EZTeam-Installer.zip");
                  })
                  .catch((err) => {
                    this.$message({
                      message: "未知异常，请稍后重试",
                      type: "error",
                    });
                  })
                  .finally(() => {
                    this.isDownloading = false;
                  });
              })
              .catch(() => {
                this.isDownloading = false;
              });
          }
        })
        .catch((err) => {
          this.$message({
            message: "未知异常，请稍后重试",
            type: "error",
          });
          this.isDownloading = false;
        });
    },
    PadZero(str) {
      //补零
      return new RegExp(/^\d$/g).test(str) ? `0${str}` : str;
    },
    formatTimeHms(timeSeconds) {
      if (timeSeconds) {
        let _seconds = parseInt(timeSeconds);
        let hours, mins, seconds;
        let result = "";
        seconds = parseInt(_seconds % 60);
        mins = parseInt((_seconds % 3600) / 60);
        hours = parseInt(_seconds / 3600);
        if (hours) {
          result =
            this.PadZero(hours) +
            ":" +
            this.PadZero(mins) +
            ":" +
            this.PadZero(seconds);
        } else {
          result = "00:" + this.PadZero(mins) + ":" + this.PadZero(seconds);
        }

        return result;
      } else {
        return null;
      }
    },
    downloadTeamPackageServer() {
      this.teamPackageDialogVisiable = true;
      this.isDownloading = true;
      this.teamPackageDialogTime = 0;
      var intervalId = setInterval(() => {
        this.teamPackageDialogTime += 1;
      }, 1000);
      this.$http
        .post("/Teams/CreateSetupClient.ashx", { teamId: this.selRow.Id })
        .then((resp) => {
          this.teamPackageDialogVisiable = false;
          clearInterval(intervalId);
          if (resp.res == 0) {
            var path = resp.data;
            window.open(`${process.env.VUE_APP_CDNURL}/${path}`, "_self");
            // var ele = document.createElement("a");
            // ele.href = `${process.env.VUE_APP_DIC}/${path}`;
            // //ele.setAttribute("target", "_blank");
            // ele.click();
          }
        })
        .catch((error) => {
          this.$message({
            message: "未知异常，请稍后重试",
            type: "error",
          });
        })
        .finally(() => {
          this.teamPackageDialogVisiable = false;
          clearInterval(intervalId);
          this.isDownloading = false;
        });
    },
    loadingChange(val) {
      this.loading = val;
    },
    /**
     * 客户端启动类型设置
     */
    khdChangeSet(val) {
      if (val == 2) {
        this.IsOpenChick = false;
      }
      this.changeSet();
    },
    /**
     * 转让主审核人
     */
    getTransferMember(val) {
      this.transferMember = val;
      this.$http
        .post("/Teams/TransferMember.ashx", {
          teamId: this.selRow.Id,
          memberId: this.transferMember[0].UsId,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              message: "设置成功",
              type: "success",
            });
            this.$emit("getData");
          }
        });
    },
    /**
     * 审核人设置
     */
    getSelApplyMember(val) {
      this.selApplyMem = val;
      this.changeSet();
    },
    /**
     * 简报可查看人设置
     */
    getVisiblesByTeam(val) {
      this.VisiblesByTeam = val;
      this.changeSet();
    },
    /**
     * 删除某一个邮箱
     */
    delAddress(ind) {
      this.addressArray = this.addressArray.filter((m, index) => ind != index);
    },
    /**
     * 添加预警接收的邮箱
     */
    addAddress() {
      this.addressArray.push({
        inputVal: null,
      });
    },
    /**
     * 团队数据库清理设置
     */
    handleDataClearSeting() {
      this.$modal.show("initdata");
    },
    /**
     * 编辑团队信息
     */
    handleEditTeam() {
      this.$modal.show("editTeam");
    },
    /**
     * 邀请口令设置
     */
    changeSetPass() {
      if (!this.setThree) {
        this.passwordVal = null;
        this.changeSet();
      }
    },
    /**
     * 设置预警邮箱
     */
    changeSetAddress() {
      if (!this.setFive) {
        this.addressArray = [];
        this.changeSet();
      } else {
        this.addressArray.push({
          inputVal: null,
        });
      }
    },
    /**
     * 详情设置
     */
    changeSet() {
      const address = this.addressArray.filter((m) => m.inputVal);
      const data = {
        teamId: this.selRow.Id,
        IsTeamCode: this.setOne ? 1 : 0,
        IsAgree: this.setTwo ? 1 : 0,
        IsInvitationCode: this.setThree ? 1 : 0,
        InvitationCode: this.passwordVal,
        IsStartCamera: this.setFour,
        IsWarn: this.setFive,
        WarnEmail: address.map((m) => m.inputVal),
        IsOpenChick: this.IsOpenChick,
        IsNeedCheck: this.IsNeedCheck,
        CanCancelAutoStart: this.CanCancelAutoStart,
        IsMonitoringIdleTime: this.IsMonitoringIdleTime,
        ClientRunType: this.ClientRunType,
        IsAutoCreateBulletin: this.IsAutoCreateBulletin,
        AuditorByTeam: this.selApplyMem.map((m) => m.UsId),
        VisiblesByTeam: this.VisiblesByTeam.map((m) => m.UsId),
        IsMonitoringNetwork: this.IsMonitoringNetwork,
        IsMonitoringPerformance: this.IsMonitoringPerformance,
        IsMonitoringFile: this.IsMonitoringFile,
        IsMonitoringPrint: this.IsMonitoringPrint,
        IsAutoStorageVideo: this.IsAutoStorageVideo,
        IsTimeScreenshot: this.IsTimeScreenshot,
        IsRealTimeScreenshot: this.IsRealTimeScreenshot,
        IsEnableLocalStore: this.IsEnableLocalStore,
        IsEnableTeamPackage: this.IsEnableTeamPackage,
      };
      this.$http.post("/Teams/TeamSet.ashx", data).then((resp) => {
        if (resp.res == 0) {
          this.$message({
            message: "设置成功",
            type: "success",
          });
        }
      });
    },
    /**
     * 邀请成员
     */
    handleInvit() {
      this.$modal.show("infoInvit");
    },
  },
};
